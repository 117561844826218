/* eslint-disable react/no-danger */
/** @jsx jsx */
import {
  jsx, Flex,
} from 'theme-ui';
import { graphql } from 'gatsby';
import Job from 'components/job';
import Layout from 'components/layout';
import {
  Header1, Header2, HighlightText, Header5,
} from 'components/primitives/text';
import { isEmpty } from 'lodash';

interface Props {
  data: any;
}

const PostsPage = ({ data: { data, pageContent } }: Props) => (
  <Layout title="Lediga tjänster">
    <Header1>{pageContent.frontmatter.title}</Header1>
    <div dangerouslySetInnerHTML={{ __html: pageContent.html }} />
    <hr sx={{ mt: 5 }} />
    <Header2
      id="lediga-tjanster"
      sx={{
        fontFamily: 'heading',
        fontWeight: 'body',
        textAlign: 'center',
        pt: 5,
        mb: 6,
        width: '100%',
      }}
    >
      <Flex sx={{ justifyContent: 'center' }}>
        <HighlightText>
          {'Lediga {{tjänster}}'}
        </HighlightText>
      </Flex>
    </Header2>
    {
        isEmpty(data.jobs)
          ? (
            <Flex
              sx={{
                justifyContent: 'center',
                mb: 8,
              }}
            >
              <Header5
                sx={{
                  color: 'muted',
                  fontWeight: 'body',
                }}
              >
                Inga lediga tjänster publicerade
              </Header5>
            </Flex>
          )
          : (
            <Flex
              sx={{
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {data.jobs.map((job: any) => (
                <Job key={job.id} post={job} />
              ))}
            </Flex>
          )
      }
  </Layout>
);

export default PostsPage;

export const pageQuery = graphql`
query JobsQuery {
  pageContent: markdownRemark(fileAbsolutePath: {regex: "/pages.*\/career.md$/"}) {
    id
    html
    frontmatter {
      title
    }
  }
  data: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "//jobs//"}}, sort: {fields: frontmatter___date, order: DESC}) {
    jobs: nodes {
      id
      frontmatter {
        slug
        seoDescription
        position
        omfattning
        date(fromNow: true, locale: "sv")
      }
    }
  }
}
`;
