/** @jsx jsx */
import {
  jsx, Flex,
} from 'theme-ui';
import { Link } from 'gatsby';
import { truncate } from 'lodash';
import { Header3, Header5 } from './primitives/text';

interface Props {
  post: {
    id: string;
    frontmatter: {
      seoDescription: string;
      slug: string;
      position: string;
      omfattning: string;
      date: string;
    };
  };
}

const Job = ({ post }: Props) => {
  const description = truncate(post.frontmatter.seoDescription, { length: 150 });

  return (
    <Link
      to={post.frontmatter.slug}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: ['100%', '100%', '100%', 'calc(50% - 20px)', 'calc(50% - 20px)', 'calc(50% - 30px)'],
        mb: [5, 5, 5, 5, 5, 5],
        borderRadius: 'default',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1,
      }}
    >
      <Flex
        sx={{
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Header3 sx={{ m: 0, mb: 2 }}>{post.frontmatter.position}</Header3>
        <Header5 sx={{ color: 'secondary' }}>{`${post.frontmatter.omfattning} - ${post.frontmatter.date}`}</Header5>
        <p>{description}</p>
        <p sx={{
          mb: 0,
          fontWeight: 'bold',
          color: 'primary',
          textAlign: 'right',
          width: '100%',
        }}
        >
          Läs mer →
        </p>
      </Flex>
    </Link>
  );
};

export default Job;
